const formatPrice = (price: number | null, locale?: string, minimumFractionDigits?: number) => {
  return new Intl.NumberFormat(locale || navigator.language, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: minimumFractionDigits !== null ? minimumFractionDigits : 2,
  }).format(price || 0);
};

const formatNumber = (number: number | null, locale?: string) => {
  return new Intl.NumberFormat(locale || navigator.language, {
    style: 'decimal',
  }).format(number || 0);
};

export { formatPrice, formatNumber };
